<template>
    <div class="right-menu shipping-right">
        
        <subHeader pageName="SMTP" />
        
        <tutorialsAction />

        <!-- SMTP section -->
        <div class="paymentSection">
            <div class="db-container">
                <div class="paymentMainBlock">

                    <!-- sendGrid Starts Here-->
                    <div class="paymentMethodWrap" id='sendgrid-step'>
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="paymentHeading">
                                    <h4>SendGrid</h4>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="actionSwitch">
                                    <label class="switch" id="sendgrid-step-status">
                                        <input type="checkbox" v-model="sendGridSmtp.isActive" :disabled="tourIsActive" @change="toggleProceedableHandle('sendgrid')">
                                        <span class="slider"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ValidationObserver ref="sendgridObserver">
                            <div class="bankingFormField" id="sendgrid-form" :class="sendGridSmtp.isActive ? 'expandForm' : ''">
                                <form @submit.prevent="validate('sendgrid')">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <ValidationProvider name="host" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="sendgrid-host-field">Host <span>*</span></label>
                                                    <input 
                                                        type="text" 
                                                        id="sendgrid-host-field" 
                                                        placeholder="Host"
                                                        v-model="sendGridSmtpData.host" 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider name="port" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="sendgrid-port-field">Port <span>*</span></label>
                                                    <input 
                                                        type="text" 
                                                        id="sendgrid-port-field"
                                                        placeholder="Port" 
                                                        v-model="sendGridSmtpData.port" 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider name="username" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="sendgrid-username-field">Username <span>*</span></label>
                                                    <input 
                                                        type="text" 
                                                        id="sendgrid-username-field" 
                                                        placeholder="Username"
                                                        v-model="sendGridSmtpData.username" 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider name="password" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="sendgrid-password-field">Password <span>*</span></label>
                                                    <input 
                                                        type="text" 
                                                        id="sendgrid-password-field"
                                                        placeholder="Password" 
                                                        v-model="sendGridSmtpData.password" 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider name="encryption" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="sendgrid-encryption-field">Encryption <span>*</span></label>
                                                    <input 
                                                        type="text" 
                                                        id="sendgrid-encryption-field"
                                                        placeholder="Encryption" 
                                                        v-model="sendGridSmtpData.encryption" 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider name="from email" rules="required|email" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="sendgrid-from-email-field">From Email <span>*</span></label>
                                                    <input 
                                                        type="text" 
                                                        id="sendgrid-from-email-field" 
                                                        placeholder="From email"
                                                        v-model="sendGridSmtpData.from_email" 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="paymentSaveBtn">
                                                <loaderBtn v-if="sendGridLoader"/>
                                                <Button id="sendgrid-save-btn" :disabled="tourIsActive" v-else>
                                                    {{ checkDataChange('sendgrid') ? 'Test & ' : '' }}Save
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ValidationObserver>
                    </div>
                    <!-- sendGrid Ends Here-->

                </div>
            </div>
        </div>

        <!--vue tour-->
        <v-tour name="smtpTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import {MessageBox, Message, Loading} from 'element-ui'
import _ from 'lodash';
import { mapGetters } from 'vuex';
import subHeader from "@/components/subHeader.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
    name:'SMTPs',
    metaInfo() {
        return {
            title: 'CRM | SMTPs | YeetCommerce',
        };
    },
    data(){
        return {

            //sendgrid start
            sendGridSmtp: {},
            tempSendGridSmtp: {},
            sendGridSmtpData: {},
            sendGridLoader: false,
            //sendgrid end

            //vue-tour
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                buttonSkip: 'Skip Tour',
                buttonPrevious: 'Previous',
                buttonNext: 'Next',
                buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //sendgrid steps
                {
                    target: '#sendgrid-step',
                    content: `<strong>SendGrid SMTP</strong><br>SendGrid SMTP configurations are available for the user.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#sendgrid-step-status',
                    content: `<strong>Active/Disabled</strong><br>Status of the SendGrid SMTP can be changed using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#sendgrid-form',
                    content: `<strong>SendGrid SMTP</strong><br>sendgrid SMTP configuration can be enabled by filling the information in the available form.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#sendgrid-host-field',
                    content: `<strong>SMTP Host</strong><br>SMTP host can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#sendgrid-port-field',
                    content: `<strong>SMTP Port</strong><br>SMTP port can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#sendgrid-username-field',
                    content: `<strong>SMTP Username</strong><br>SMTP username can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#sendgrid-password-field',
                    content: `<strong>SMTP Password</strong><br>SMTP password can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#sendgrid-encryption-field',
                    content: `<strong>SMTP Encryption</strong><br>SMTP encryption can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#sendgrid-from-email-field',
                    content: `<strong>SMTP From Email</strong><br>SMTP from email can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#sendgrid-save-btn',
                    content: `<strong>Save Details</strong><br>Sendgrid SMTP details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
            ],
            tourIsActive: false
        }
    },
    components:{
        subHeader,
        tutorialsAction,
        loaderBtn
    },
    computed:{
    },
    watch:{
    },
    methods:{

        validate(form){

            if(form == 'sendgrid'){

                this.$refs.sendgridObserver.validate().then((success) => {

                    if(!success){

                        const errors = Object.entries(this.$refs.sendgridObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                        this.$refs.sendgridObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

                    }else{

                        this.updateSmtpHandle('sendgrid');

                    }

                });

            }

        },

        async toggleProceedableHandle(form){

            if(form == 'sendgrid'){

                if(this.sendGridSmtp.isActive){

                    this.$refs.sendgridObserver.validate().then((success) => {

                        if(!success){

                            const errors = Object.entries(this.$refs.sendgridObserver.errors)
                            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                            this.$refs.sendgridObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

                            this.$notify({
                                type: 'info',
                                title: 'SMTP Configuration Missing',
                                message: "Kindly set SMTP configurations and save it to enable it.",
                            });

                            this.$refs.sendgridObserver.reset();

                        }else{

                            if(this.sendGridSmtp.isActive != this.tempSendGridSmtp.isActive){

                                this.smtpStatusToggle(form);

                            }

                        }

                    });

                }else{

                    if(this.sendGridSmtp.isActive != this.tempSendGridSmtp.isActive){

                        this.smtpStatusToggle(form);

                    }

                }

            }

        },
        async smtpStatusToggle(form){

            let formData = new FormData();

            if(form == 'sendgrid'){

                formData.append('smtp_id',this.sendGridSmtp.id);
                formData.append('isActive',this.sendGridSmtp.isActive ? 1 : 0);

            }

            let loader = Loading.service({
                text: "The SMTP is undergoing status update. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.post('/crm/smtp/toggle',formData)
                if(res.data.status_code == "1487" || res.data.status_code == "1488"){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                }

            }catch(error){

                if(error.response){

                    if(error.response.data.error.includes('Only one SMTP')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                loader.close();

                this.loadSmtps();

            }

        },

        async updateSmtpHandle(form){

            if(form == 'sendgrid'){

                let formData = new FormData();

                formData.append('smtp_id', this.sendGridSmtp.id)
                formData.append('isActive', this.sendGridSmtp.isActive ? 1 : 0);

                formData.append('host',this.sendGridSmtpData.host);
                formData.append('port',this.sendGridSmtpData.port);
                formData.append('username',this.sendGridSmtpData.username);
                formData.append('password',this.sendGridSmtpData.password);
                formData.append('encryption',this.sendGridSmtpData.encryption);
                formData.append('from_email',this.sendGridSmtpData.from_email);

                formData.append('test', this.checkDataChange(form) ? 1 : 0);

                let loader = Loading.service({
                    text: "The SMTP is being updated. Please wait!",
                    fullscreen: true,
                });

                this.sendGridLoader = true
                try{
                    let res = await this.$axios.post('/crm/smtp/update',formData);
                    if(res.data.status_code == "1486"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                    }
                }catch(error){

                    if(error.response){

                        if(error.response.data.error.includes('encountered an error')){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error,
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    loader.close();

                    this.sendGridLoader = false

                    this.loadSmtps();

                }

            }

        },
        checkDataChange(form){

            if(form == 'sendgrid'){

                if(this.tempSendGridSmtp && this.tempSendGridSmtp.data){

                    let tempData = JSON.parse(this.tempSendGridSmtp.data);

                    if((tempData.host == this.sendGridSmtpData.host) &&
                    (tempData.port == this.sendGridSmtpData.port) &&
                    (tempData.username == this.sendGridSmtpData.username) &&
                    (tempData.password == this.sendGridSmtpData.password) &&
                    (tempData.encryption == this.sendGridSmtpData.encryption) &&
                    (tempData.from_email == this.sendGridSmtpData.from_email)){

                        return false

                    }else{

                        return true
                        
                    }

                }

            }

        },

        async loadSmtps(){

            let loader = Loading.service({
                text: "Fetching SMTPs. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.get('/crm/smtp/all');
                if(res.data.status_code == "1485"){

                    res.data.smtps.forEach((smtp) => {

                        if(smtp.name == 'SendGrid'){

                            this.sendGridSmtp = smtp
                            this.tempSendGridSmtp = _.cloneDeep(smtp)

                            let smtpData = JSON.parse(this.sendGridSmtp.data)

                            this.sendGridSmtpData = smtpData

                        }

                    })

                }
            }catch(error){

                this.$message({
                    type: 'Error',
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }finally{

                loader.close();

            }

        },

        //tour methods
        startSmtpTour(){

            this.$tours.smtpTour.start()

            this.tourIsActive = true

            this.sendGridSmtp.isActive = false

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 6){

                this.sendGridSmtp.isActive = true

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 6){

                this.sendGridSmtp.isActive = false
                
            }

        },
        handleTourStop(){

            if(this.$tours.smtpTour && this.$tours.smtpTour.currentStep != -1){
                
                this.loadSmtps();

                this.tourIsActive = false

            }

        },
        handleTourFinish(){

            this.loadSmtps();

            this.tourIsActive = false

        },
        handleTourSkip(){

            if(this.$tours.smtpTour && this.$tours.smtpTour.currentStep != -1){
               
                this.loadSmtps();

                this.tourIsActive = false

            }

        },
    },
    async beforeMount(){

        //loading SMTP data
        this.loadSmtps();

    },
}
</script>

<style>

</style>